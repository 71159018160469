import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(private jwtHelperService: JwtHelperService) { }

  isAuthorized(): boolean {
    // get token from local storage or state management
    const token = sessionStorage.getItem('token');
    if (token === null) {
      return false;
    } else {
      // decode token to read the payload details
      const decodeToken = this.jwtHelperService.decodeToken(token);
      // check if it was decoded successfully, if not the token is not valid, deny access
      if (!decodeToken) {
        return false;
      }
      return true;
      // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
    }
  }

}