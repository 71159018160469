import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Injectable()

export class MyInterceptor implements HttpInterceptor {

  public message: any;
  public path: any;

  public nonce: string = '35A90B73C39D97'; // Default Value
  public timestamp: number = 1615971782; // Default Value

  // Character String for Generate Nonce
  public characterString = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';

  public privateKey: string = environment.privateKey;
  public secretKey: string = environment.secretKey;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Generate Nonce
    this.nonce = '';
    for (let i = 0; i < 8; i++) {
      const rnum = Math.floor(Math.random() * this.characterString.length);
      this.nonce += this.characterString.substring(rnum, rnum + 1);
    }

    // Generate TimeStamp
    this.timestamp = Math.floor(new Date().getTime() / 1000);

    // Generate Token
    const generateHmacToken = CryptoJS.HmacSHA256(this.secretKey + this.timestamp + this.nonce, this.privateKey).toString(CryptoJS.enc.Hex);
    const token = sessionStorage.getItem('token');

    // Clone request with appropriate headers
    let headers = httpRequest.headers
      .set('nonce', this.nonce.toString())
      .set('timestamp', this.timestamp.toString())
      .set('token', generateHmacToken.toString());

    if (token) {
      headers = headers.set('authorization', 'Bearer ' + token).set('device_type', '3').set('application_type', '5');
    }

    const authReq = httpRequest.clone({ headers: headers });

    const isLocalLoader = httpRequest.headers.get('x-document-local-loader');
    if (isLocalLoader != "true") {
      this.ngxLoader.start();
    }

    return next.handle(authReq).pipe(
      tap(
        event => this.handleResponse(httpRequest, event),
        error => this.handleError(httpRequest, error)
      )
    );
  }

  handleResponse(httpRequest: HttpRequest<any>, event: any) {
    if (event instanceof HttpResponse) {
      if (event.body && (event.body.code === 401 || event.body.code === 1)) {
        this.handleUnauthorized();
      }
      const isLocalLoader = httpRequest.headers.get('x-document-local-loader');
      if (isLocalLoader != "true") {
        this.ngxLoader.stop();
      }
    }
  }

  handleError(httpRequest: HttpRequest<any>, error: any) {
    const isLocalLoader = httpRequest.headers.get('x-document-local-loader');
    if (isLocalLoader != "true") {
      this.ngxLoader.stop();
    }
    if (error.status == 400 || error.status == 401 || error.status == 404 || error.status == 500) {
      this.handleUnauthorized();
    }
  }

  private handleUnauthorized() {
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 1000);
  }

}
