export const environment = {
  production: false,
  baseUrl: "https://dev.cehwapi.mymanu.com/api",
  socketUrl: 'https://dev.cehwsai.mymanu.com',
  imageUrl: "https://d2neamglna4h3f.cloudfront.net/SubscriptionImages/",
  assetsPath: {
    image: './assets/img/',
  },
  gl_flk: '14f78l9',
  cr_chw: '3j2o5p7xy',
  and_mnd: 'abx3y8n',
  sg_rat: 'tlop0cdxy',
  privateKey: "4AF8AC8E124B6484BDDC5A9EB3D49",
  secretKey: "PXGuKjNtjzLiyLRE0GzykvGcaZO5uWE0",
  headerXAccessKey: 'adbohkjfbdshjbsdjffkbj',

  apiKeyMaps: 'AIzaSyDVQM1M7QnFWEOBWu5rdgnx2yWU9h1aQrk',
  urlMaps: 'https://maps.googleapis.com/maps/api',
  googleDriveConfig: {
    clientId: '703537762169-e9q9hf9tio4s3kdbh8d9ugi1elp4l5sq.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/drive.file',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    src: 'https://apis.google.com/js/api.js',
    driveLink: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'
  },
  onedriveConfig: {
    clientId: 'f8b39f0c-672e-4c1c-a959-476259588b4e',
    authority: 'https://login.microsoftonline.com/81c32ced-3a3d-4b23-ad04-457a99dd6c78',
    redirectUri: 'https://devtranslate.mymanu.com/onedrive-login',
    graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
    graphEndpointDrive: 'https://graph.microsoft.com/v1.0/me/drive/root:/',
    postLogoutRedirectUri: "https://devtranslate.mymanu.com/document-translation"
  },
  stripePublicKey: 'pk_test_51GujScLfYk4HeohuUdm1X2eG0fzRohDWjJauDrQbiT4PZnfc4V1fuMVua1s3ddNJmtO6SSoq26fe9tQ6PIF8jCc900C4L3Wtcu'
};