import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dat } from '../helpers/dat';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Injectable()

export class HeaderInterceptor implements HttpInterceptor {

  constructor(private ngxService: NgxUiLoaderService) { }

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Extract the value of the custom header
    const isLocalLoader = originalRequest.headers.get('x-document-local-loader');

    const shouldStartLoader = isLocalLoader !== 'true';

    // Start the loader conditionally
    if (shouldStartLoader) {
      this.ngxService.start();
    }

    const jwtTok = Dat.get()?.token;

    if (jwtTok) {
      const requestWithAuthToken = originalRequest.clone({
        setHeaders: {
          authorization: `${jwtTok}`,
          'x-access-key': environment.headerXAccessKey,
        },
        body: originalRequest.body instanceof FormData ? originalRequest.body : this.sendMsg(originalRequest.body)
      });

      // Stop the loader conditionally
      if (isLocalLoader !== 'true') {
        this.stopLoader(shouldStartLoader);
      }
      return next.handle(requestWithAuthToken);
    }
    else {
      // Stop the loader conditionally
      if (isLocalLoader !== 'true') {
        this.stopLoader(shouldStartLoader);
      }
      return next.handle(originalRequest);
    }
  }

  private sendMsg(msg: {}) {
    const body: {} = msg;
    return body;
  }

  private stopLoader(shouldStop: boolean) {
    if (shouldStop) {
      this.ngxService.stop();
    }
  }

}