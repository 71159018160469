import * as CryptoJS from 'crypto-js';

export class Dat {

  static set(data: {}): void {
    const toKN: any = localStorage.getItem('__to_KN');
    const dat = CryptoJS.AES.encrypt(JSON.stringify(data), toKN).toString();
    localStorage.setItem('__dat', dat);
  }

  static setQR(data: string): void {
    const toKN: any = localStorage.getItem('__to_KN');
    const dat: any = localStorage.getItem('__dat');
    const bytes = CryptoJS.AES.decrypt(dat, toKN);
    if (bytes.toString()) {
      let jsonData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      jsonData.Base64QRCode = data;
      const toKN: any = localStorage.getItem('__to_KN');
      const dat = CryptoJS.AES.encrypt(
        JSON.stringify(jsonData),
        toKN
      ).toString();
      localStorage.setItem('__dat', dat);
    }
  }

  static get() {
    if (localStorage.getItem('__to_KN') && localStorage.getItem('__dat')) {
      const toKN: any = localStorage.getItem('__to_KN');
      const dat: any = localStorage.getItem('__dat');
      const bytes = CryptoJS.AES.decrypt(dat, toKN);
      if (bytes.toString()) {
        let jsonData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let dat: any = {
          email: jsonData.Email || jsonData.userEmail || '',
          verifyToken: jsonData.VerifyToken || '',
          token: jsonData.Token || '',
          base64QRCode: 'data:image/png;base64,' + jsonData.Base64QRCode || '',
          qrCodekey: jsonData.qrCodeKey || '',
          userName: jsonData.userName || '',
          userPhoneNumber: jsonData.userPhoneNumber || '',
          userVerified: jsonData.userVerified || '',
          accountType: jsonData.AccountType || '',
          status: jsonData.status || '',
        };
        return dat;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  static clear() {
    localStorage.clear();
  }

}