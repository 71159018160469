import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dat } from '../helpers/dat';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()

export class RequestInterceptor implements HttpInterceptor {

  constructor(private ngxService: NgxUiLoaderService) { }

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Extract the value of the custom header
    const isLocalLoader = originalRequest.headers.get('x-document-local-loader');

    const shouldStartLoader = isLocalLoader !== 'true';

    // Start the loader conditionally
    if (shouldStartLoader) {
      this.ngxService.start();
    }

    const verifyToken = Dat.get()?.verifyToken;

    // If there's no verify token and the body is FormData, handle the request directly
    if (!verifyToken && originalRequest.body instanceof FormData) {

      // Stop the loader conditionally
      this.stopLoader(shouldStartLoader);
      return next.handle(originalRequest);
    }

    // Clone the request and set the verify token header
    const headers = { 'x-verify-token': verifyToken || '' };
    const requestWithAuthToken = originalRequest.clone({
      setHeaders: headers,
      body: originalRequest.body instanceof FormData ? originalRequest.body : this.sendMsg(originalRequest.body)
    });
    // Stop the loader conditionally
    this.stopLoader(shouldStartLoader);
    return next.handle(requestWithAuthToken);
  }

  private sendMsg(msg: {}) {
    const body: {} = msg;
    return body;
  }

  private stopLoader(shouldStop: boolean) {
    if (shouldStop) {
      this.ngxService.stop();
    }
  }

}